var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":_vm.$t('recette.detailsCheque'),"width":"50%"},on:{"ok":_vm.handleOk,"cancel":_vm.handleOk},model:{value:(_vm.localChequeVisible),callback:function ($$v) {_vm.localChequeVisible=$$v},expression:"localChequeVisible"}},[(_vm.activeCheques.montantCheque || _vm.activeCheques.montantEspece)?_c('div',[_c('a-descriptions',[_c('a-descriptions-item',{attrs:{"span":"2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ant-descriptions-item-label"},[_vm._v(_vm._s(_vm.$t("recette.chequeMontantTotal")))])]},proxy:true}],null,false,333898846)},[_vm._v(" "+_vm._s(_vm.activeCheques.montantCheque)+" ")]),(_vm.activeCheques.montantEspece)?_c('a-descriptions-item',{attrs:{"span":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ant-descriptions-item-label"},[_vm._v("Montant espèce :")])]},proxy:true}],null,false,2230275655)},[_vm._v(" "+_vm._s(_vm.activeCheques.montantEspece)+" ")]):_vm._e()],1),_c('a-divider')],1):_vm._e(),_c('div',[(_vm.loadedChequesData[0])?_c('div',[_c('a-descriptions',[(
              _vm.loadedChequesData[0].relatedTransactions &&
              _vm.loadedChequesData[0].relatedTransactions.length > 0
            )?_c('a-descriptions-item',{attrs:{"stlye":"width:100%"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ant-descriptions-item-label"},[_vm._v(_vm._s(_vm.$t("recette.relatedPayments")))])]},proxy:true}],null,false,4194010486)},[_c('table',{staticClass:"table table-striped",attrs:{"stlye":"width:100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("recette.numSerie")))]),_c('th',[_vm._v(_vm._s(_vm.$t("comptabilite.nom")))]),_c('th',[_vm._v(_vm._s(_vm.$t("recette.montantCheque")))])])]),_c('tbody',_vm._l((_vm.loadedChequesData[0].relatedTransactions),function(t){return _c('tr',{key:t._id},[_c('td',[_vm._v(_vm._s(t.serial))]),_c('td',[_vm._v(" "+_vm._s(t.typeAS == "A" ? "Activity/" + t.payment.activity.designation : "Scolarité/" + t.payment.classroom.name)+" ")]),_c('td',[_vm._v(_vm._s(t.montantCheque))])])}),0)])]):_vm._e()],1),_c('a-descriptions',[(
              _vm.loadedChequesData[0].relatedTransactions &&
              _vm.loadedChequesData[0].relatedTransactions.length > 0
            )?_c('a-descriptions-item',{attrs:{"stlye":"width:100%"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"ant-descriptions-item-label"},[_vm._v("Chèques associés :")])]},proxy:true}],null,false,2544351331)},[_c('table',{staticClass:"table table-striped",attrs:{"stlye":"width:100%"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("recette.numero")))]),_c('th',[_vm._v(_vm._s(_vm.$t("recette.titulaire")))]),_c('th',[_vm._v(_vm._s(_vm.$t("recette.banque")))]),_c('th',[_vm._v(_vm._s(_vm.$t("recette.montantCheque")))]),_c('th',[_vm._v(_vm._s(_vm.$t("recette.dateEcheance")))]),_c('th',[_vm._v("Versable ?")])])]),_c('tbody',_vm._l((_vm.loadedChequesData),function(t){return _c('tr',{key:t._id},[_c('td',[_vm._v(_vm._s(t.num || "--"))]),_c('td',[_vm._v(" "+_vm._s(t.titulaire || "--")+" ")]),_c('td',[_vm._v(_vm._s(t.bank || "--"))]),_c('td',[_vm._v(_vm._s(t.montant.toFixed(2)))]),_c('td',[_vm._v(" "+_vm._s(_vm.moment(t.dateEchance).format("DD/MM/YYYY") || "--")+" ")]),_c('td',[_c('a-tag',{attrs:{"color":t.versable ? 'green' : 'red'}},[_vm._v(_vm._s(t.versable ? "Oui" : "Non"))])],1)])}),0)])]):_vm._e()],1)],1):_c('div',[_c('a-skeleton',{attrs:{"active":"","paragraph":{ rows: 1 }}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }