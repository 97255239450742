<template>
  <div>
    <a-modal
      v-model="localChequeVisible"
      :title="$t('recette.detailsCheque')"
      width="50%"
      @ok="handleOk"
      @cancel="handleOk"
    >
      <div v-if="activeCheques.montantCheque || activeCheques.montantEspece">
        <a-descriptions>
          <a-descriptions-item span="2">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">{{
                $t("recette.chequeMontantTotal")
              }}</span>
            </template>
            {{ activeCheques.montantCheque }}
          </a-descriptions-item>
          <a-descriptions-item span="3" v-if="activeCheques.montantEspece">
            <template v-slot:label>
              <span class="ant-descriptions-item-label">Montant espèce :</span>
            </template>
            {{ activeCheques.montantEspece }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider></a-divider>
      </div>
      <div>
        <div v-if="loadedChequesData[0]">
          <a-descriptions>
            <a-descriptions-item
              stlye="width:100%"
              v-if="
                loadedChequesData[0].relatedTransactions &&
                loadedChequesData[0].relatedTransactions.length > 0
              "
            >
              <template v-slot:label>
                <span class="ant-descriptions-item-label">{{
                  $t("recette.relatedPayments")
                }}</span>
              </template>
              <table class="table table-striped" stlye="width:100%">
                <thead>
                  <tr>
                    <th>{{ $t("recette.numSerie") }}</th>
                    <th>{{ $t("comptabilite.nom") }}</th>
                    <th>{{ $t("recette.montantCheque") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="t in loadedChequesData[0].relatedTransactions"
                    :key="t._id"
                  >
                    <td>{{ t.serial }}</td>
                    <td>
                      {{
                        t.typeAS == "A"
                          ? "Activity/" + t.payment.activity.designation
                          : "Scolarité/" + t.payment.classroom.name
                      }}
                    </td>
                    <td>{{ t.montantCheque }}</td>
                  </tr>
                </tbody>
              </table>
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions>
            <a-descriptions-item
              stlye="width:100%"
              v-if="
                loadedChequesData[0].relatedTransactions &&
                loadedChequesData[0].relatedTransactions.length > 0
              "
            >
              <template v-slot:label>
                <span class="ant-descriptions-item-label"
                  >Chèques associés :</span
                >
              </template>
              <table class="table table-striped" stlye="width:100%">
                <thead>
                  <tr>
                    <th>{{ $t("recette.numero") }}</th>
                    <th>{{ $t("recette.titulaire") }}</th>
                    <th>{{ $t("recette.banque") }}</th>
                    <th>{{ $t("recette.montantCheque") }}</th>
                    <th>{{ $t("recette.dateEcheance") }}</th>
                    <th>Versable ?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="t in loadedChequesData" :key="t._id">
                    <td>{{ t.num || "--" }}</td>
                    <td>
                      {{ t.titulaire || "--" }}
                    </td>
                    <td>{{ t.bank || "--" }}</td>
                    <td>{{ t.montant.toFixed(2) }}</td>
                    <td>
                      {{ moment(t.dateEchance).format("DD/MM/YYYY") || "--" }}
                    </td>
                    <td>
                      <a-tag :color="t.versable ? 'green' : 'red'">{{
                        t.versable ? "Oui" : "Non"
                      }}</a-tag>
                    </td>
                  </tr>
                </tbody>
              </table>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div v-else>
          <a-skeleton active :paragraph="{ rows: 1 }" />
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import apiClient from "@/services/axios";
import moment from "moment";

export default {
  props: ["chequeVisible", "activeCheques"],
  async mounted() {
    this.getChequeDetails(this.activeCheques);
  },
  data() {
    return {
      loadedChequesData: [],
      localChequeVisible: this.chequeVisible, // Create a local copy
    };
  },
  methods: {
    moment,
    getChequeDetails(ids) {
      const uniqueIds = ids.map((e) => e._id);
      apiClient
        .post("/cheque/filter/multiple", {
          ids: uniqueIds,
        })
        .then((res) => {
          this.loadedChequesData = res.data;
          console.log("this.loadedChequesData ", this.loadedChequesData);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    handleOk() {
      this.$emit("close");
      this.loadedChequesData = [];
    },
  },
  watch: {
    chequeVisible(newVal) {
      this.localChequeVisible = newVal; // Keep the local copy in sync with the prop
    },
  },
};
</script>

<style scoped>
.success .ant-btn-primary {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
